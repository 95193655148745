import '../Css/footer.css'

const Footer = () => {
    return (
        <footer>
            <strong>Mohamad Mourad</strong>
            <span>mohamadmourad511@gmail.com</span>
        </footer>
    );
}
 
export default Footer;